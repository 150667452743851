<template>
  <div>  
    <b-row>
      <b-col class="col-12 col-md-6 col-lg-4 d-flex align-items-stretch" v-for="(card, index) in card_resumes" :key="index">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-tools">
          </div>
          <h6 class="title-tools">{{card.title}}</h6>
          <h2>{{card.value}}</h2>
          <p class="text-muted">{{ card.value_small }}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <b-card class="table-responsive" id="table-searcher-card">
          <b-col class="d-flex justify-content-end align-items-center flex-wrap p-1">
            <div class="d-flex align-items-center flex-wrap ml-2 mb-1 mb-sm-0">    
              <b-button variant="flat-secondary" @click="show_sidebar = true"><b-icon icon="sliders"></b-icon> {{$t('search.filters')}}</b-button>               
              <b-button 
                variant="flat-secondary"
                class="button-filters"
                @click="filters = {}; getOrders()"
              >
              <feather-icon icon="Trash2Icon"/>
              {{$t('creator.clearFilter')}}
            </b-button>
            </div>
          </b-col>         
        </b-card>
      </b-col>
      <b-col>
        <div class="position-relative">
          <div v-if="info_table.length > 0">
            <b-table
              :items="info_table"
              id="table-searcher"
              :fields="$t(`creator.table_casting_calls.orders`)"
              head-variant="light"
              :current-page="current_page"
              :per-page="per_page"
            >
      
              <template #cell(id)="data">        
                <h6>{{data.index + 1}}</h6>
              </template>
  
              <template #cell(proposal)="data">
                <div class="d-flex align-items-center">
                  <b-avatar 
                    class="mr-1" 
                    rounded 
                    variant="dark" 
                    :icon="isVideo(data.item.proposal.images[0].media) ? 'camera-video' : ''"
                    :src="data.item.proposal.images[0].media"
                  ></b-avatar>
                  <div>
                    <b-link class="text-overflow-proposals" @click="$router.push({name: 'view_proposal', params: { slug: data.item.proposal.slug}})">{{data.item.proposal.title}}</b-link>
                    <p class="text-muted m-0">
                      {{$t(`creator.type_of_service`).find((type) => type.value === data.item.proposal.work_type).text}}
                    </p>
                  </div>
                </div>
              </template>
  
              <template #cell(seller)="data">
                <div class="d-flex align-items-center">
                  <b-avatar class="mr-1" variant="dark" :src="verifyAsset(data.item.seller.avatar)"></b-avatar>
                  <div>
                    <b-link class="name-transactions" @click="data.item.seller.vanity_name ? $router.push({name: 'user_profile', params: { user: data.item.seller.vanity_name}}) : ''">{{data.item.seller.first_name}} {{data.item.seller.last_name}}</b-link>
                    <p class="m-0 text-muted" v-if="data.item.status === 'paid'">{{ data.item.seller.email }}</p>
                  </div>
                </div>
              </template>
  
              <template #cell(current_state)="data">
                <div class="d-flex align-items-center">
                  <b-badge :variant="colorStatus(data.item.status)">{{ $t(`creator.status_orders_brand.${data.item.status}`) }}</b-badge>
                </div>
              </template>
  
              <template #cell(invoice_number)="data">
                <div class="d-flex align-items-center">
                  <b-badge class="badge-invoice-number">{{ data.item.transaction ? data.item.transaction.order_id : '--' }}</b-badge>
                </div>
              </template>
  
              <template #cell(price)="data">
                <div class="d-flex align-items-center">
                  {{ data.item.transaction ? `$ ${decimalFixed(data.item.transaction.amount, 2)}` : '--' }}
                </div>
              </template>
  
              <template #cell(ordered_on)="data">
                <div class="d-flex align-items-center">
                  {{ data.item.transaction ? data.item.transaction.paid_at : '--'}}
                </div>
              </template>
  
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  dropright  
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="$router.push({name: 'chat-proposal', params: {chat_uuid: data.item.chat_uuid}})">
                    <span>{{ $t('creator.viewChat') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            
            <b-pagination 
              v-model="current_page" 
              :total-rows="info_table.length" 
              :per-page="per_page"
              class="d-flex justify-content-center"
            ></b-pagination>
          </div>
          <b-card class="container-loader-searcher" v-if="!loader_casting_calls">
            <b-spinner class="spinner-table-searcher"></b-spinner>
          </b-card>
          <template v-if="info_table.length === 0">
            <no-Results
              :title= "$t('creator.noOrders')"
              :subtitle= "$t('creator.searchCreators')"
              :show_button= "true"
              :text_button="$t('creator.goMarket')"
              :btn_action="goMarket"

            />
          </template>
        </div>
      </b-col>
    </b-row>

    <b-sidebar
      v-model="show_sidebar"
      right
      shadow
      backdrop
      header-class="container-title-sider"
      width="400px"
      bg-variant="white"
    >
      <template #header>
        <div>
          <span class="title-sider">{{ $t("creator.titleSiderOrder") }}</span>
          <feather-icon
            class="close-sider-network"
            icon="XIcon"
            size="20"
            @click="show_sidebar = false"
          />
        </div>
      </template>

      <div class="mt-2 px-2 mb-2">
        <div class="">
          <b-form-group class="text-muted" :label="$t('creator.startDate')">
            <div class="fields">
              <b-form-datepicker
                v-model="filters.init_order"
                class="size-inputs w-100"
                :placeholder="$t('creator.startDate')"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="es"
              />
            </div>
          </b-form-group>

          <b-form-group class="text-muted" :label="$t('creator.endDate')">
            <div class="fields">
              <b-form-datepicker
                v-model="filters.end_order"
                class="size-inputs w-100"
                :placeholder="$t('creator.endDate')"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="es"
              />
            </div>
          </b-form-group>

          <b-form-group class="text-muted" :label="$t('creator.statusOrder')">
            <b-form-checkbox
              v-model="filters.status"
              :value="index"
              class="content-checkbox"
              v-for="(i, index) in $t('creator.status_orders_brand')"
              :key="index"
            >{{ i }}</b-form-checkbox
            >
          </b-form-group>
        </div>
      </div>
      <template #footer>
        <div class="button-spacing">
          <b-button class="btn-clear" variant="outline-secondary" @click="filters = {}; getOrders()">
            <b-icon icon="trash" class="cursor-pointer" />
          </b-button>
          <b-button class="size-button" variant="primary" @click="getOrders">
          {{ $t('creator.updateResultFilters') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BBadge,
  BSpinner,
  BCol,
  BRow,
  BCard,
  BTable,
  BAvatar,
  BButton,
  BLink,
  BSidebar,
  BFormGroup,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  // BDropdownDivider,
  BPagination,
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';
import { verifyAsset } from '@/libs/utils/urls'
import { isVideo, decimalFixed } from '@/libs/utils/formats'

export default {
  name: 'ordersBrand',
  components: {
    BFormGroup,
    BFormDatepicker,
    BDropdown,
    BFormCheckbox,
    BDropdownItem,
    BPagination,
    BBadge,
    BSpinner,
    BButton,
    BCard,
    BCol,
    BRow,
    BTable,
    BAvatar,
    BLink,
    BSidebar,
    // BDropdownDivider,
    // BDropdown,
    // BDropdownItem,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
  },
  props: {
    summary: {
      type: Object,
    }
  },
  data() {
    return {
      decimalFixed,
      isVideo,
      verifyAsset,
      card_resumes: [],
      filters: {},
      show_sidebar: false,
      loader_casting_calls: false,
      info_table: [],
      current_page: 1,
      per_page: 10,
    }
  },
  created() {
    this.getOrders();
    this.updateCardResumes();
  },
  watch: {
    '$i18n.locale': 'updateCardResumes'
  },
  methods: {
    updateCardResumes() {
      this.card_resumes = [
        {
          title: this.$t('creator.activeAll'),
          value: this.summary.active_todos,
          value_small: `${this.summary.waiting_seller} ${this.$t('creator.waitingSeller')}`,
        },
        {
          title: this.$t('creator.ordersActive'),
          value: this.summary.active_orders,
          value_small: `${this.summary.awaiting_acceptance} ${this.$t('creator.awaitingAcceptance')}`,
        },
        {
          title: this.$t('creator.currentBudget'),
          value: `$${this.summary.budget}`,
          value_small: '',
        }
      ];
    },
    goMarket() {
      this.$router.push({name: 'search_proposal'});
    },
    getOrders() {
      proposals_services.getProposalsPaid(this.filters).then((response) => {
        this.loader_casting_calls = true;
        this.info_table = response.results;
      });
    },
    colorStatus(status) {
      return (status === 'paid') ? 'light-success' : 'light-warning'
    },
  },
}
</script>
<style scoped>
  .badge-invoice-number {
    color: #3b8dab;
    background-color: rgba(67,153,170,.1);
  }
  .text-overflow-proposals {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.container-loader-searcher {
   height: 200px;
   width: 100%;
   background-color: white;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .spinner-table-searcher {
   margin-top: 2em;
   height: 6em;
   width: 6em;
   color: #acacac;
 }
 .btn-clear {
    width: 20%;
  }
.btn-clear:focus {
  width: 20%;
  background: transparent !important;
}
.button-filters {
  margin-left: 5px;
}
.button-filters:focus {
  margin-left: 5px;
  background-color: transparent !important;
}
.background-left-proposal {
  width: 10px;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0px;
}
.title-tools{
  font-weight: bolder;
  color: #1ABC9C;
}
.default-color-background-tools {
  background: linear-gradient(180deg, #50D0AD 0%, #29809A 100%);
}
.button-spacing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.size-button {
  width: 75%;
  height: 40px;
}
</style>
<style>

  #table-searcher-card .card-body {
    padding: 0;
  }
  .table-responsive {
    overflow: visible;
  }
  #table-searcher td {
    border-bottom: 1px solid #eff3f6;
  }
  #table-searcher tr {
    background-color: white;
  }
  #table-searcher tr:hover {
    background-color: #EFF3F6;
    cursor: pointer;
    transition: all 100ms;
  }
  .thead-light th {
    background-color: white !important;
  }
  .checkbox-table-searcher {
    display: none !important;
  }
  .card-shadow:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .card-shadow{
    width: 100%;
  }
</style>